<template>
  <modal
    :show="show"
    title="Create User"
    :custom-classes="['modal-bg-side-nav']"
    @close="close"
  >
    <template
      v-if="true"
      class="modal-body"
    >
      <text-input
        v-model="name"
        class="create-user-text-input"
        placeholder="Name.."
        :required="true"
      />
      <text-input
        v-model="email"
        class="create-user-text-input"
        placeholder="Email.."
        :required="true"
      />
    </template>
    <template #footer>
      <div class="modal-footer has-2-col">
        <action-button
          button-display="BTN_NEG"
          message="CANCEL"
          :custom-classes="{'top-nav-item': true}"
          @onClick="close"
        />
        <action-button
          button-display="BTN_PRIMARY"
          message="CREATE"
          :custom-classes="{'top-nav-item': true}"
          :is-loading="confirmCreateIsLoading"
          @onClick="confirmCreate"
        />
      </div>
    </template>
  </modal>
</template>
<script>
import { useToast } from 'vue-toastification';
import { warningMessages } from '@/store/helpers/display/toastMessages';
import Modal from './Modal.vue';
import ActionButton from '../general/buttons/ActionButton.vue';
import TextInput from '../forms/TextInput.vue';

export default {
  components: {
    ActionButton,
    Modal,
    TextInput,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    confirmCreateIsLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'confirmCreate'],
  data() {
    return {
      toast: useToast(),
      name: '',
      email: '',
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirmCreate() {
      this.$log.info('Attempting to create user:', this.name, this.email);

      // Check all required fields are filled.
      if (!(this.name && this.email)) {
        this.toast.warning(warningMessages.CREATE_USER_MISSING_DATA);
        return;
      }

      // Check email is in email format.
      if (!/\S+@\S+\.\S+/.test(this.email)) {
        this.toast.warning(warningMessages.CREATE_USER_BAD_EMAIL);
        return;
      }

      this.$emit('confirmCreate', this.name, this.email);
    },
  },
};
</script>
