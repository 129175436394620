<template>
  <splitpanes>
    <pane
      class="main-content-pane"
      size="70"
    >
      <div class="main-container">
        <div class="settings-container">
          <div class="settings-header">
            <h1>Settings</h1>
            <settings-actions :can-see-create-user-button="canSeeCreateUserButton" />
          </div>
          <tabs
            :tabs="settingsPageTabs"
          >
            <template
              #actions
            >
              <search-bar
                :class="{'hidden-search-bar': $route.name !== 'users' }"
                placeholder="Search Users..."
                @update-search="updateSearch"
              />
            </template>
          </tabs>
        </div>
      </div>
    </pane>
    <pane
      v-if="isPanelOpen"
      class="pane-details main-content-pane"
      size="30"
      style="minWidth: 450px"
    >
      <aside
        :id="teleportationId"
        :style="{ width: '100%', zIndex: '1', position: 'relative' }"
      />
    </pane>
  </splitpanes>
</template>
<script>
import { useToast } from 'vue-toastification';
import { Splitpanes, Pane } from 'splitpanes';
import { successMessages, errorMessages } from '@/store/helpers/display/toastMessages';
import { mapActions } from 'vuex';
import isSet from '@/store/helpers/isSet';
import debounce from 'lodash.debounce';
import { allowedStates } from '@/store/helpers/storeState';
import SearchBar from '@/components/general/SearchBar.vue';
import Tabs from '@/components/tabs/Tabs.vue';
import SettingsActions from '@/components/settings/SettingsActions.vue';
import 'splitpanes/dist/splitpanes.css';
import { provide, ref } from 'vue';

export default {
  components: {
    SettingsActions,
    Splitpanes,
    Pane,
    SearchBar,
    Tabs,
  },
  setup() {
    const teleportationId = ref('users-sidepanel-container');
    const isPanelOpen = ref(false);
    const closePanel = () => { isPanelOpen.value = false; };
    const openPanel = () => { isPanelOpen.value = true; };

    provide('panelProvider', {
      teleportationId, isPanelOpen, closePanel, openPanel,
    });

    return { teleportationId, isPanelOpen, closePanel };
  },
  data() {
    return {
      toast: useToast(),
      showCreateUserModal: false,
      confirmCreateUserIsLoading: false,
      canSeeCreateUserButton: false,
      usersState: allowedStates.IS_BLANK,
      searchString: '',
      showPermissionsPanel: false,
      settingsPageTabs: [
        {
          title: 'Users',
          to: 'users',
        },
      ],
    };
  },
  async created() {
    this.canSeeCreateUserButton = await (this.checkPermission('CAN_ADD_USERS'));
  },
  mounted() {
    this.$log.info('Users loading...');
    this.loadUsers();
  },
  methods: {
    ...mapActions({
      init: 'users/init',
      createUser: 'users/createUser',
      searchUsers: 'users/searchUsers',
      checkPermission: 'localisation/checkPermission',
    }),
    async loadUsers() {
      this.usersState = allowedStates.IS_LOADING;
      await this.init()
        .then(() => {
          this.$log.info('Users loaded successfully');
          this.usersState = allowedStates.IS_READY;
        })
        .catch((e) => {
          this.$log.error('Error loading users', e);
          this.toast.error(errorMessages.GET_USERS);
          this.usersState = allowedStates.IS_ERRORING;
        });
    },
    openCreateUserModal() {
      this.showCreateUserModal = true;
    },
    closeCreateUserModal() {
      this.showCreateUserModal = false;
    },
    async onConfirmCreate(name, email) {
      this.confirmCreateUserIsLoading = true;
      await (this.createUser({ name, email }))
        .then(() => {
          this.closeCreateUserModal();
          this.toast.success(successMessages.CREATE_USER);
        })
        .catch((e) => {
          this.$log.error('Failed to create user', e);

          // Display any visible user creation errors, otherwise display
          // a general error message.
          if (isSet(e.response.data.errors)) {
            this.toast.error(e.response.data.errors);
          } else {
            this.toast.error(errorMessages.CREATE_USER);
          }
        })
        .finally(() => {
          this.confirmCreateUserIsLoading = false;
        });
    },
    async applySearchUsers() {
      this.usersState = allowedStates.IS_LOADING;
      try {
        await this.searchUsers(this.searchString);
        this.$log.info('Users updated successfully');
        this.usersState = allowedStates.IS_READY;
      } catch (e) {
        this.$log.error('Error loading users', e);
        this.toast.error(errorMessages.GET_USERS);
        this.usersState = allowedStates.IS_ERRORING;
      }
    },
    updateSearch: debounce(async function (query) { // eslint-disable-line func-names
      this.$log.debug('Debounce search triggered:', query);
      this.searchString = query;
      await this.applySearchUsers();
    }, 700),
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  padding: 0;
  position: relative;
}

.main-content-pane {
  display: -webkit-inline-box;
}
.settings-container {
  display: flex;
  flex-flow: column;
  height: 100%;
  overscroll-behavior: auto;
  width: 100%;
}
.users-filter-bar {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  padding: 0 32px;
}

.settings-header {
  display: flex;
  justify-content: space-between;
  padding: 0 32px;
}
</style>
